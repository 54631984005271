export default {
    methods: {
      async getApplicantData(aid, requiredObjects){
        try{
          // ?filter[status]=3 // ejemplo de filtro
          let params = "with[]=" + requiredObjects.join("&with[]=");
          let response = await axios.get(`/api/v1/applicant/${aid}?${params}`);
          return response.data;
        }
        catch(e){
          this.warn(e);
          return null;
        }
      },
      async getApplicantPaymentInfo(ApplicantId){
        try{
          const response = await axios.get(`api/v1/applicant/${ApplicantId}/paymentData`);
          return response.data;
        }
        catch (e) {
          this.failed = true;
          return null;
        }
      },
      async getUserAccounts() {
        try {
          const res = await axios.get(`api/v1/userBankAccounts/${this.UserId}`);
          return res.data;
        }
        catch (e) {
          return null;
        }
      },
      moralTypeValue(){
        return 0;
      },
      moralForeignTypeValue(){
        return 3;
      },
      moralFinancialTypeValue(){
        return 4;
      },
      personalTypeValue(){
        return 1;
      },
      personalForeignTypeValue(){
        return 2;
      },
      userIsMoral(person_type){
        return (person_type === this.moralTypeValue() || person_type === this.moralForeignTypeValue());
      },
      userIsForeign(person_type){
        return (person_type === this.moralForeignTypeValue() || person_type === this.personalForeignTypeValue());
      },
    },
  }